var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "示例页面", fixed: "" },
          slot: "header",
        },
        [
          _c("fb-button", {
            attrs: {
              slot: "left",
              icon: "add",
              size: "small",
              type: "primary",
            },
            on: {
              click: function ($event) {
                return _vm.$router.push({ path: "/createcase" })
              },
            },
            slot: "left",
          }),
          _c("fb-button", {
            attrs: {
              slot: "right",
              icon: "icon-test",
              size: "small",
              type: "primary",
            },
            on: {
              click: function ($event) {
                return _vm.$router.push({ path: "/nav" })
              },
            },
            slot: "right",
          }),
        ],
        1
      ),
      _c("router-view", { staticClass: "home-router-view" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }