<template>
  <fb-page>
    <fb-header slot="header" title="示例页面" fixed>
      <fb-button
        slot="left"
        icon="add"
        @click="$router.push({ path: '/createcase' })"
        size="small"
        type="primary"
      >
      </fb-button>
      <fb-button
        slot="right"
        icon="icon-test"
        @click="$router.push({ path: '/nav' })"
        size="small"
        type="primary"
      >
      </fb-button>
      <!-- <fb-button slot="right"
                 icon="edit"
                 size="small"
                 type="primary"
                 @click="$router.push({path: '/editor/19014?caseDocumentId=1&fromType=NOCASE'})">
      </fb-button> -->
    </fb-header>
    <router-view class="home-router-view"></router-view>
  </fb-page>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'case-detail',
  components: {},
  data() {
    return {
      // 点击菜单出现确认弹出层
      handleActionConfirm: false,
      // 菜单确认类型
      actionParam: '',
      // 弹出菜单层title
      actionTitle: '',
      active: 0,
      showActionMenu: false,
      tabs: [
        {
          name: '案件',
          number: 7,
          style: 1,
          path: '/home/case'
        },
        {
          name: '文书',
          number: 3,
          style: 1,
          path: '/home/document'
        },
        {
          name: '办公',
          number: 4,
          path: '/home/office'
        }
      ]
    }
  },
  methods: {
    ...mapMutations('caseDetail', ['setCurrentTabName']),
    goPage(index, title) {
      const activePage = this.tabs.filter(item => item.name === title)[0]
      const { path } = activePage
      this.$router.push({ path })
    }
  },
  mounted() {
    // 设置监听聚焦事件
    // document.body.addEventListener('focus', this.focusHandler, true)
    // this['setCurrentTabName']('DocumentTab')
  }
}
</script>

<style lang="stylus">
.home-router-view
  max-height calc(100% - 93px)
  overflow hidden
.dev-tabs.van-tabs
  .van-tabs__wrap
    .van-tabs__nav
      .van-tabs__line
        background-color #6db7ff
        height 2px
</style>
